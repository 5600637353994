import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { Draggable } from 'gsap/Draggable'
import { InertiaPlugin } from 'gsap/InertiaPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

export default function useGsap() {
    let smoothScroller

    gsap.registerPlugin(
        ScrollTrigger,
        ScrollSmoother,
        Draggable,
        ScrollToPlugin,
        InertiaPlugin,
    )

    gsap.matchMedia().add(`(min-width: 1024px)`, () => {
        smoothScroller = ScrollSmoother.create({
            wrapper: '#smooth-wrapper',
            content: '#smooth-content',
        })

        return () => smoothScroller.kill()
    })

    return { gsap, smoothScroller }
}
